import React from 'react'
import './Services.css'

import img1 from '../../assets/ServicesImg/image 3.png'
import img2 from '../../assets/ServicesImg/image 2.png'
import img3 from '../../assets/ServicesImg/image 5.png'
import img4 from '../../assets/ServicesImg/image 6.png'
import img5 from '../../assets/ServicesImg/image 15.png'
import img6 from '../../assets/ServicesImg/image 16.png'

const Data = [
    {
        imgSrc: img1,
        subService: 'Software Development',
        moreInfo: 'Your ideas are turned into software by our IT team, which creates the foundation for successful business operations.'
    },
    {
        imgSrc: img2,
        subService: 'Web Development',
        moreInfo: 'High-performance websites that instantly captivate users and encourage conversions are what we design and develop.'
    },
    {
        imgSrc: img3,
        subService: 'Product Maintainance',
        moreInfo: 'We\'ll maintain your software up to date so that it can power your company all day.'
    },
    {
        imgSrc: img4,
        subService: 'IT Consulting',
        moreInfo: 'Hire our best brains to help your business successfully undergo a digital transformation that supports your goals.'
    },
    {
        imgSrc: img5,
        subService: 'UI/UX Design',
        moreInfo: 'With a knowledgeable team and practical design solutions, create a digital product that is genuinely focused on the needs of the user.'
    },
    {
        imgSrc: img6,
        subService: 'Corporate Training',
        moreInfo: 'Tackle toda\'s digital skills gap by professionally upskilling your workforce!'
    },
]

const Services = () => {
    return (
        <div className="serviceSection">
            <div className="heading">
                <h1>Services</h1>
            </div>
            <div className="servi">
                {
                    Data.map(({ imgSrc, subService, moreInfo }) => {
                        return (
                            <div className='ourServices'>
                                <div className="imgdiv">
                                    <img src={imgSrc} alt="ii" />
                                </div>
                                <div className='Serve'>
                                    <div className="subMainService">
                                        <h3>{subService}</h3>
                                    </div>
                                    <div className="serveInfo">
                                        <p>{moreInfo}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Services