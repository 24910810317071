import './App.css';
import About from './components/About Us/About';
import Footer from './components/Footer/Footer';
import Main from './components/Main/Main';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigationbar from './components/Navbar/Navigationbar'
import Services from './components/Sevices/Services';
import Work from './components/WithUs/Work';
import Homeie from './components/Home/Homeie'
function App() {
  return (
    <div className="App">
      <Navigationbar />
      
        <Routes>
          <Route path='/' element={<Homeie />} />
          <Route path='/main' element={<Main/>} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/service" element={<Services />} />
          <Route path="/work" element={<Work />} />
        </Routes>
      
      <Footer />
    </div>
  );
}

export default App;
