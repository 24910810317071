import React from 'react'
import './Main.css'
import bgImage from '../../assets/Hero-background.jpg'

const Main = () => {
    return (
        <section className='mainSection'>   
            <div className='mainHeading'>
                <h1>Software Development Company</h1>
            </div>
            <div className='subMain'>
                <h2>“To develop the software that ease your business.”</h2>
                <p>Using a group of CTOs, strategists, and developers, bring your software to life. </p>
            </div>
        </section>
    )
}

export default Main