import React from 'react'
import './About.css'

const About = () => {
  return (
    <div className='aboutSection'>
        <div className='heading'>
            <h1>About Us</h1>
        </div>
        <div className='content'>
            <p>Belageri Technologies Pvt. Ltd. built by a team with deep software development experience in using customer listening to identify and implement business solutions for clients, our platform offers executives and assured path to proactively strengthen their business.</p>
        </div>

    </div>
  )
}

export default About