import React from 'react'
import './Work.css'

import img1 from '../../assets/workwithus/image 10.png'
import img2 from '../../assets/workwithus/image 13.png'
import img3 from '../../assets/workwithus/image 14.png'
import img4 from '../../assets/workwithus/image 17.png'


const Working = [
    {
        imgSrc: img1,
        subService: 'Technical Partner',
        moreInfo: 'We will put together a highly qualified team of engineers to create your software so you can focus on growing your business.'
    },
    {
        imgSrc: img2,
        subService: 'Dedicated to Your Goals',
        moreInfo: 'This item is for you. You and your Mona Lisa. Your masterpiece. We\'ll demonstrate to the world what occurs when visionaries and software developers collaborate.'
    },
    {
        imgSrc: img3,
        subService: 'You Set the Budget',
        moreInfo: 'It\'s your budget.  We comprehend. In order to make sure we stay on course, we\'ll align our goals and meet frequently. You determine  the monthly spending limit and are free to adjust it as needed.'
    },
    {
        imgSrc: img4,
        subService: 'A-Class Team',
        moreInfo: 'With more than 12 years of experience, we guarantee digital transformation maturity throughout the organization by utilizing our in-depth technological knowledge and unmatched software engineering proficiency.'
    }

]

const Work = () => {
    return (
        <div className="workSection">
            <div className="heading">
                <h1>Work With Us</h1>
            </div>
            <div className="servi">
                {
                    Working.map(({ imgSrc, subService, moreInfo }) => {
                        return (
                            <div className='ourWork'>
                                <div className="imgdiv">
                                    <img src={imgSrc} alt="ii" />
                                </div>
                                <div className='works'>
                                    <div className="subMainService">
                                        <h3>{subService}</h3>
                                    </div>
                                    <div className="workInfo">
                                        <p>{moreInfo}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Work