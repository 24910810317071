import React, { Component } from 'react'
import About from '../About Us/About'
import Services from '../Sevices/Services'
import Work from '../WithUs/Work'
import Main from '../Main/Main'



export class Homeie extends Component {
    render() {
        return (
            <>
            <Main/>
            <About/>
            <Services/>
            <Work/>
            </>
        )
    }
}



export default Homeie