import React from 'react'
import './Footer.css'
import img1 from '../../assets/Footer/image 18.png'
import img2 from '../../assets/Footer/image 19.png'

const Footer = () => {
    return (
        <div className='footerSection'>
            <div className="companyName">
                <h1>Belageri Technologies Private Limited</h1>
            </div>
            <div className="footContent">
                <div className="addressDetails">
                    <img src={img1} alt="ew" />
                    <div className="address">
                        <p>H.NO-11-1400/136/7, Akkamahadevi Colony, KHB, Opp. High Court,<br />
                        Kalaburagi, Karnataka, India, 585103<br />
                            9739292092<br />
                            contactus@belageri.com<br />
                        </p>
                    </div>
                </div>
                <div className='dutyHours'>
                    <div className="workingHours">
                        <img src={img2} alt="ee" />
                        <div className="timings">
                            <p>MONDAY TO FRIDAY 10:00 - 18:00</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='copyright'>
                <p> &#169; 2024 - All Rights Reserved. Designed and Developed by <span> Belageri Technologies.</span></p>
            </div>
        </div>
    )
}

export default Footer