import React, { useState } from 'react'
import './Navigationbar.css'
import { SiYourtraveldottv } from 'react-icons/si'
import { AiFillCloseCircle } from 'react-icons/ai'
import { TbGridDots } from 'react-icons/tb'
import Logo from '../../assets/BTPL_Logo.jpg'
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

const Navigationbar = () => {
  /*code to show navbar */
  const [active, setActive] = useState('navBar')
  const showNav = () => {
    setActive('navBar activeNavbar')
  }
  /*code to remove navbar*/
  const removeNav = () => {
    setActive('navBar')
  }

  //code to add backroungd color to the header
  //   const [transparent,setTransparent] = useState('header')
  //   const addBg = ()=>{
  //     if(window.scrollY >= 10){
  //       setTransparent('header activeHeader')
  //     }
  //     else{
  //       setTransparent('header')
  //     }
  //   }
  //   window.addEventListener('scroll', addBg)


  return (
    <section className='navBarSection'>
      <div className='header'>
        <div className='logoDiv'>
          <a href='#' className='logo'>
            <img className='imgLogo' src={Logo}></img>
          </a>
          <div className='logoName'>
            <h1>Belageri Technologies
            </h1>

          </div>
        </div>

        <div className={active}>
          <Navbar >
            <Container>
              <Nav>
                <div className='navLists'>
                  <div className="navItem">
                    <Link className='NavLink' to='/'>Home</Link>
                  </div>
                  <div className="navItem">

                    <Link className='NavLink' to='/aboutus'>About us</Link>
                  </div>
                  <div className="navItem">

                    <Link className='NavLink' to='/service'>Services</Link>
                  </div>
                  <div className="navItem">

                    <Link className='NavLink' to='/work'>Work with us</Link>
                  </div>
                  <div className="navItem">

                    <Link className='NavLink' to='#'>How we work </Link>
                  </div>
                  {/* <Link className='navItem' to='/contact'>Contact Us</Link> */}
                </div>
              </Nav>
            </Container>
          </Navbar>
          <div onClick={removeNav} className='closeNavbar'>
            <AiFillCloseCircle className='icon' />
          </div>
        </div>
        <div onClick={showNav} className='toggleNavbar'>
          <TbGridDots className='icon' />
        </div>
      </div>



    </section>
  )

}

export default Navigationbar